module.exports = [{
      plugin: require('/home/travis/build/Kitura/kitura.dev/website/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/Kitura.svg"},
    },{
      plugin: require('/home/travis/build/Kitura/kitura.dev/website/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200},
    },{
      plugin: require('/home/travis/build/Kitura/kitura.dev/website/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
