// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blogs-js": () => import("/home/travis/build/Kitura/kitura.dev/website/src/templates/blogs.js" /* webpackChunkName: "component---src-templates-blogs-js" */),
  "component---src-templates-docs-js": () => import("/home/travis/build/Kitura/kitura.dev/website/src/templates/docs.js" /* webpackChunkName: "component---src-templates-docs-js" */),
  "component---src-pages-blogs-js": () => import("/home/travis/build/Kitura/kitura.dev/website/src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-getting-started-js": () => import("/home/travis/build/Kitura/kitura.dev/website/src/pages/getting-started.js" /* webpackChunkName: "component---src-pages-getting-started-js" */),
  "component---src-pages-index-js": () => import("/home/travis/build/Kitura/kitura.dev/website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-js": () => import("/home/travis/build/Kitura/kitura.dev/website/src/pages/learn.js" /* webpackChunkName: "component---src-pages-learn-js" */),
  "component---src-pages-news-js": () => import("/home/travis/build/Kitura/kitura.dev/website/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-js": () => import("/home/travis/build/Kitura/kitura.dev/website/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

